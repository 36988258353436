import React from 'react'
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import 'prismjs/themes/prism.css'

import userConfig from '../../config'

import Header from '../components/Header'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  
  @import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
  }

  body {
    background: #f9fafc;
    font-family: 'Roboto', sans-serif; 
    line-height: 1.6;
    padding: 50px 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
  }

  .gatsby-highlight {
    border-bottom: 1px solid #e0e6ed;
    border-top: 1px solid #e0e6ed;
    margin: 15px -100px;
    padding: 0;

    pre[class*="language-"] {
      margin: 0;
      padding: 25px 100px;
    }
  }

  pre[class*="language-"] {
    background: rgba(245, 245, 245, 1);
    color: rgb(65, 76, 94);  }

  @media only screen and (max-width: 870px) {
    .gatsby-highlight {
      margin: 15px -15px;

      pre[class*="language-"] {
        padding: 25px;
      }
    }
  }
`

class Template extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <GlobalStyle />
        <Header config={userConfig} />
        {children}
      </div>
    )
  }
}

export default Template
