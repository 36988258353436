import styled from 'styled-components'

const P = styled.p`
  color: #666d71;
  display: block;
  font-size: 2.6em;
  margin: 50px 0 0 0;
  text-align: center;
`

export default P
