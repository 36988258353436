module.exports = {
  title: 'Denver Coder', // Required
  author: 'Tim Myers', // Required
  description: 'VueJS | ReactJS | C#.NET | WordPress',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 3, // Required
  social: {
    website: 'https://denvercoder.com',
    github: 'https://github.com/denvercoder',
    twitter: 'https://twitter.com/denvercoder',
    linkedin: 'https://linkedin.com/in/denvercoder',
  },
  pathPrefix: '/posts',
  siteUrl: 'https://denvercoder.com',
}
